<template>
  <div class="panoramaPageWrap en">
    <!-- 720全景 -->
    <m-layout-bar>
      <div
        class="panoramaWrap"
        :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}"
      >
        <div class="content shadowBox">
          <div class="titleWrap">
            <img class="title" :src="require('../assets/image/panorama_en/title.png')" alt />
            <div class="btn ifocus_right" @click="clickHandle">
              <img :src="require('../assets/image/panorama_en/btn.png')" alt />
            </div>
          </div>

          <!--全景视频 列表 -->
          <!--给每一行的最后一个item设置margin=0 :class="{'marginRightZero':(index+1)%3==0}" -->
          <div
            class="listWrap"
            :class="{'marginRightZero':(index+1)%3==0}"
            v-for="(item,index) in dataInfo"
            :key="index"
          >
            <!-- 单个全景视频 -->
            <div class="item ifocus_up" @click="showVideo(item.link)">
              <div class="panoramaWrap">
                <img class="itemPic" :src="baseUrl + item.cover" alt />
                <img class="iconPlay" :src="require('../assets/image/video_en/play_icon.png')" alt />
              </div>
              <div class="desc">
                <span class="title">{{item.title}}</span>
                <span class="subtitle">{{item.info}}</span>
                <!-- <div class="countWrap">
                  <img class="iconCount" :src="require('../assets/image/panorama_en/count.jpg')" alt />
                  <span class="total">1120</span>
                </div>-->
              </div>
            </div>
          </div>
          <div class="pagWrap">
            <Pagination v-show="total>0" :total="total" @change="changePagination"></Pagination>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
import Pagination from "@/components/pagination";

import axios from "axios";
import config from "@/utils/config";
import { checkIsNumber } from "@/utils/util.js";

export default {
  components: {
    [LayoutBar.name]: LayoutBar,
    Pagination
  },
  data() {
    return {
      bgImgUrl: require("../assets/image/panorama/bg.png"),
      videoList: new Array(7),
      maskShow: false,
      total: 0, // 必传传递参数total
      current: 1,
      dataInfo: new Array()
    };
  },
  mounted() {
    this.getData(config.vrUrl_en);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    // 翻页触发的事件
    changePagination(cur) {
      this.current = cur;
      let url = config.vrUrl_en.split("=")[0] + "=" + cur;
      // console.log("url:", url, cur);
      this.getData(url);
    },
    // 设置翻页控件参数
    setPageBarParams(count) {
      if (count != null) {
        if (count == "") {
          this.total = 0;
        } else {
          let flag = checkIsNumber(count);
          if (flag) {
            this.total = count;
          }
        }
      }
    },
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          this.setPageBarParams(res.data.count);
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            // console.log("res.data.data:", res.data, this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    clickHandle() {
      this.$router.push({ path: "/video_en" });
    },
    showVideo(link) {
      // "https://720.geeker.com.cn/rest/indexNew/view.action?id=10904"
      let openWin = window.open(link);
      openWin.opener = null;
    }
  }
};
</script>


<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.videoPageWrap {
  height: 100%;
}
.marginRightZero {
  margin-right: 0 !important;
}
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  .video {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .close {
    position: absolute;
    top: 10;
    right: 10;
    color: $main-white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
}
.panoramaWrap {
  border: 1px solid transparent;
  // background:blue;
  .content {
    width: 1400px;
    margin: 82px auto 50px auto;
    border: 1px solid $main-gray;
    padding: 19px 47px 50px 47px;
    margin-bottom: 70px;
    background: $main-white;
    // background:yellow;
    .titleWrap {
      position: relative;
      .btn {
        // text-align:right;
        position: absolute;
        display: inline-block;
        right: 0;
        bottom: 10px;
      }
    }
    .listWrap {
      width: 407px;
      display: inline-block;
      margin-right: 80px;
      border: 1px solid transparent;
      box-sizing: border-box;
      > .item {
        width: 407px;
        height: 340px;
        margin-top: 47px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        > .panoramaWrap {
          position: relative;
          width: 407px;
          height: 230px;
          .itemPic {
            width: 405px;
            height: 228px;
            border-radius: 10px;
          }
          .iconPlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          // background:red;
        }
        .desc {
          position: relative;
          // padding-top: 16px;
          // padding-left: 10px;
          padding: 16px 20px 15px 20px;
          height: 108px;
          box-sizing: border-box;
          overflow: hidden;

          > .title,
          > .subtitle {
            display: block;
          }
          > .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          > .subtitle {
            // width: 407px;
            height: 44px; //高度限制
            font-size: 13px;
            overflow: hidden;
          }
          > .countWrap {
            position: absolute;
            top: 21px;
            right: 10px;
            color: $font-grayDarkD;
            font-size: 12px;
            > .iconCount {
              margin-right: 5px;
              vertical-align: bottom;
            }
          }
          > .time {
            position: absolute;
            width: 121px;
            height: 31px;
            top: -26px;
            left: 10px;
            font-size: 12px;
            color: $main-white;
          }
        }
        &:hover {
          box-shadow: 1px 0px 4px #acacac;
        }
      }
    }
    .listWrap:last-of-type {
      margin-right: 0;
    }
    > .pagWrap {
      width: 400px;
      padding-top: 20px;
      margin: 0 auto;
      text-align: center;
    }
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .panoramaWrap {
    .content {
      .titleWrap {
        position: relative;
        .btn {
          right: 50%;
        }
      }
    }
  }
}
@media (max-width: $screen-sp-min) {
  .panoramaWrap {
    .content {
      .titleWrap {
        position: relative;
        .btn {
          right: 20%;
        }
      }
    }
  }
}
</style>
